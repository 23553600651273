import OffersSlider from '../../components/home/OffersSlider';
import FeaturedProperties from '../../components/home/FeaturedProperties';
import Footer from '../../components/core/footer/Footer';
import useFetchOffer from '../../hooks/useFetchOffer';
import { Helmet } from 'react-helmet-async';

const Home: React.FC = () => {
  const { offer } = useFetchOffer(); // Consumir las ofertas con tu hook
  const offerImages = offer.map((o) => o.image);
  const offerId = offer.map((o) => o.id);

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>InmoTrust | Administración de Propiedades</title>
        <meta name="description" content="InmoTrust, expertos en arriendo y administración de propiedades en Chile." />
        <meta name="keywords" content="InmoTrust, propiedades, arriendo, administración de propiedades, venta de casas en Chile" />
        <meta name="author" content="InmoTrust" />
      </Helmet>
      <div className="flex-grow">
      <OffersSlider 
        images={offerImages} // Pasar imágenes al slider
        slidesToShow={1} // Número de imágenes visibles a la vez
        slideSize="h-[350px]"
        id={offerId[0]}
        autoplay={true}
      />
         <FeaturedProperties page="home" />
      </div>
      <Footer />
    </div>
  );
};

export default Home;