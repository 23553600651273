import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import OurServicePages from '../pages/services/OurServicesPages';
import PropertiesPage from '../pages/properties/PropertiesPage';
import AboutUsPage from '../pages/aboutus/AboutUsPage';
import ContactPage from '../pages/contact/ContactPage';
import PropertyDetailsPage from '../pages/properties/PropertyDetailsPage';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="servicios" element={<OurServicePages />} />
      <Route path="propiedades" element={<PropertiesPage />} />
      <Route path="sobreNosotros" element={<AboutUsPage />} />
      <Route path="contacto" element={<ContactPage />} />
      <Route path='propertyDetails' element={<PropertyDetailsPage/>}/>
    </Routes>
  );
};

export default AppRoutes;