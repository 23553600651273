import React, { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';

interface LazyLoadImageProps {
  src: string;
  alt: string;
  className?: string;
  loaderClassName?: string;
  fixedHeight?: string; // Altura fija mientras se carga la imagen
  containerClassName?: string;
  onClick?: () => void; // Nueva propiedad opcional para manejar clics
  pageContext?: 'details' | 'default'; // Contexto de la página (detalles o cualquier otro)
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({ 
  src, 
  alt, 
  className = '', 
  loaderClassName = '', 
  fixedHeight = 'h-48', 
  containerClassName = '', 
  onClick,
  pageContext = 'default' // Por defecto es "default"
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      className={`relative w-full ${
        pageContext === 'details' 
          ? 'h-full' // En el contexto "details", usa altura completa
          : !isLoaded ? fixedHeight : ''
      } ${containerClassName}`}
    >
      {!isLoaded && (
        <div className={`absolute inset-0 flex items-center justify-center bg-gray-100 z-10 ${loaderClassName}`}>
          <ThreeDots height="40" width="40" color="#4fa94d" ariaLabel="loading" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`w-full object-cover ${isLoaded ? '' : 'hidden'} ${className}`}
        onLoad={handleImageLoad}
        onClick={onClick} // Asignar la propiedad onClick
      />
    </div>
  );
};

export default LazyLoadImage;