import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

interface LoadingProps {
  loading: boolean;
  height?: string;
  width?: string;
  radius?: string;
  color?: string;
  children?: React.ReactNode;
}

const Loading: React.FC<LoadingProps> = ({ 
  loading, 
  height = "80", 
  width = "80", 
  radius = "9", 
  color = "#4fa94d", 
  children 
}) => {
  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <ThreeDots 
            height={height} 
            width={width} 
            radius={radius} 
            color={color} 
            ariaLabel="three-dots-loading" 
            visible={true} 
          />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loading;