import 'leaflet/dist/leaflet.css';
import emailIcon from '../../assets/email-icon.svg';
import phoneIcon from '../../assets/phone-icon.svg';
import locationIcon from '../../assets/location-icon.svg';
import Footer from '../core/footer/Footer';
import MapComponent from '../core/MapComponent';
import EmailForm from '../core/EmailForm';

const Contact = () => {
  return (
    <div className="min-h-screen bg-gunmetal">
      <header className="py-12 text-center bg-white text-gunmetal">
        <h1 className="text-4xl font-bold">Contáctanos</h1>
        <p className="mt-2 text-lg text-charcoal">Estamos aquí para ayudarte. ¡Envíanos un mensaje y nos pondremos en contacto!</p>
      </header>

      <div className="container mx-auto px-6 py-16 grid gap-8 md:grid-cols-2">
        {/* Formulario de contacto */}
        <div className="bg-white p-8 shadow-lg rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Formulario de Contacto</h2>
          <EmailForm />
        </div>

        {/* Información de contacto y mapa */}
        <div className="flex flex-col space-y-8">
          {/* Información de contacto */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Nuestra Información</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <img src={phoneIcon} alt="Phone" className="w-6 h-6 mr-4" />
                <p className="text-gray-700">+56956403139</p>
              </div>
              <div className="flex items-center">
                <img src={emailIcon} alt="Email" className="w-6 h-6 mr-4" />
                <p className="text-gray-700">contacto@inmotrust.cl</p>
              </div>
              <div className="flex items-center">
                <img src={locationIcon} alt="Location" className="w-6 h-6 mr-4" />
                <p className="text-gray-700">Av. Providencia 1234, Santiago, Chile</p>
              </div>
            </div>
          </div>

          {/* Mapa interactivo */}
          <MapComponent center={[-33.1830047, -70.6733444]} zoom={30} styleClass="h-64 rounded-lg shadow-lg" showPopup={true} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;