import { Helmet } from "react-helmet-async";
import AboutUs from "../../components/aboutUs/AboutUs";

const AboutUsPage: React.FC = () => {

    return (
      <div className="flex flex-col min-h-screen">
        <Helmet>
        <title>InmoTrust | Administración de Propiedades</title>
        <meta name="description" content="InmoTrust, expertos en arriendo y administración de propiedades en Chile." />
        <meta name="keywords" content="InmoTrust, propiedades, arriendo, administración de propiedades, venta de casas en Chile" />
        <meta name="author" content="InmoTrust" />
      </Helmet>
        <div className="flex-grow">
          <AboutUs/>
        </div>
      </div>
    );
  };
  
  export default AboutUsPage;