import { useEffect, useState } from "react";
import { AboutUsDocument } from "../domain/model/AboutUsDocument";
import { getAboutUsDocumentUseCaseInstance } from "../domain/usecase/useCaseInstances";

const useFetchAboutUsDocument = () => {
    const [aboutUseDocument, setAboutUseDocument] = useState<AboutUsDocument[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchAboutUsDocument = async () => {
        setLoading(true);
        setError(null);
        try {
          const aboutUsDocumentData = await getAboutUsDocumentUseCaseInstance.execute();
          setAboutUseDocument(aboutUsDocumentData);
        } catch (err) {
          setError("Error fetching data "+ err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchAboutUsDocument();
    }, []);
  
    return { aboutUseDocument, loading, error };
  };
  
  export default useFetchAboutUsDocument;