import { Property } from '../../domain/model/Property';
import { AboutUsDocumentRepository, EmailConfigRepository, FeatureConfigItemRepository, OfferRepository, OurServicesRepository, PropertyRepository } from '../../domain/repositories/Repository';
import { firebaseFirestore } from '../../core/firebaseFirestore';
import { collection, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { Offer } from '../../domain/model/Offer';
import { OurService } from '../../domain/model/OurServices';
import { FeatureConfigItem } from '../../domain/model/FeatureConfigItem';
import { AboutUsDocument } from '../../domain/model/AboutUsDocument';
import { EmailConfig } from '../../domain/model/EmailConfig';

export class PropertyRepositoryImpl implements PropertyRepository {
    async getProperties(): Promise<Property[]> {
      const propertiesCollection = collection(firebaseFirestore, 'properties');
      const snapshot = await getDocs(propertiesCollection);
      return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
        ...doc.data() as Property,
      }));
    }
  }
 
  export class OfferRepositoryImpl implements OfferRepository {
    async getOffer(): Promise<Offer[]> {
        const offersCollection = collection(firebaseFirestore, 'offers');
        const snapshot = await getDocs(offersCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as Offer,
        }));
    }
  }

  export class OurServicesRepositoryImpl implements OurServicesRepository {
    async getOurServices(): Promise<OurService[]> {
        const ourServicesCollection = collection(firebaseFirestore, 'OurServices');
        const snapshot = await getDocs(ourServicesCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as OurService,
        }));
    }
  }

  export class FeatureConfigItemRepositoryImpl implements FeatureConfigItemRepository {
    async getFeatureConfigItem(): Promise<FeatureConfigItem[]> {
        const featureConfigItemCollection = collection(firebaseFirestore, 'featureIcons');
        const snapshot = await getDocs(featureConfigItemCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as FeatureConfigItem,
        }));
    }
  }

  export class AboutUsDocumentRepositoryImpl implements AboutUsDocumentRepository {
    async getAboutUsDocument(): Promise<AboutUsDocument[]> {
        const aboutUsDocumentCollection = collection(firebaseFirestore, 'aboutUs');
        const snapshot = await getDocs(aboutUsDocumentCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as AboutUsDocument,
        }));
    }
  }

  export class EmailConfigRepositoryImpl implements EmailConfigRepository {
    async getEmailConfig(): Promise<EmailConfig> {
      try {
        const emailConfigCollection = collection(firebaseFirestore, "emailConfig");
        const snapshot = await getDocs(emailConfigCollection);
        if (snapshot.empty) {
          throw new Error("No se encontró ninguna configuración de email.");
        }
        const doc = snapshot.docs[0];
        const data = doc.data();
  
        if (!data) {
          throw new Error("El documento de configuración de email está vacío.");
        }
        return {
          serviceId: data.serviceId,
          templateId: data.templateId,
          userId: data.userId,
          successMessage: data.successMessage,
          errorMessage: data.errorMessage,
        };
      } catch (error) {
        console.error("Error al obtener configuración de email:", error);
        throw error;
      }
    }
  }
