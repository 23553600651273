import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface OffersSliderProps {
  images: string[];
  slidesToShow?: number;
  slideSize?: string;
  fullScreen?: boolean;
  showDots?: boolean;
  autoplay?: boolean;
  id?: number;
  onSlideChange?: (image: string) => void;
  gap?:string,
}

const OffersSlider: React.FC<OffersSliderProps> = ({
  images,
  slidesToShow = 1,
  slideSize = 'w-20 h-20',
  fullScreen = false,
  showDots = true,
  autoplay = true,
  id = 0,
  onSlideChange,
  gap = '0',
}) => {
  const settings = {
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    autoplay,
    autoplaySpeed: 3000,
    arrows: !fullScreen, // Ocultar flechas en modo pantalla completa
    beforeChange: (_: any, next: any) => {
      if (onSlideChange) {
        onSlideChange(images[next]); // Llamar a la función con la próxima imagen
      }
    },
  };

  return (
    <div className={`my-4 mx-4 md:flex md:flex-col  ${fullScreen ? 'w-full h-screen'  : ''}`}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={`${id}-${index}`}
            className={`relative ${fullScreen ? 'w-full h-screen' : slideSize} cursor-pointer space-x-${gap}`}
          >
            <img
              src={image}
              className={`w-full h-full object-cover ${fullScreen ? 'rounded-none' : 'rounded-lg'}`}
              alt={`Slide ${index}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OffersSlider;