import React, { useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import useFetchProperties from '../../hooks/useFetchProperties';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropertyFeatures from '../core/PropertyFeature';
import LazyLoadImage from '../core/LazyLoadImage';
import { Link } from 'react-router-dom';

const FeaturedProperties: React.FC<{ page: 'home' | 'properties' }> = ({ page }) => {
  const { properties, loading, error } = useFetchProperties();

  // Filtrar y asegurar que haya una propiedad destacada en la primera posición
  const displayedProperties = page === 'home'
    ? (() => {
      // Filtrar las propiedades destacadas y normales
      const highlightedProperty = properties.find(property => property?.highlightedText);
      const otherProperties = properties.filter(property => !property?.highlightedText);

      // Construir el array asegurándose de que haya datos en las posiciones 0 y 1 de otherProperties
      if (highlightedProperty) {
        return [
          otherProperties[0] ?? highlightedProperty,  // Usar highlightedProperty si otherProperties[0] no existe
          highlightedProperty,
          otherProperties[1] ?? highlightedProperty,  // Usar highlightedProperty si otherProperties[1] no existe
        ];
      }

      // Si no hay destacadas, devolver las primeras tres propiedades normales (siempre con verificación de existencia)
      return properties.slice(0, 3).filter(Boolean);
    })()
    : properties
      .slice() // Copiar el arreglo para evitar mutaciones
      .sort((a, b) => (b.highlightedText ? 1 : 0) - (a.highlightedText ? 1 : 0)); // Ordenar propiedades destacadas primero
  useEffect(() => {
    if (error) {
      toast.error('Error loading properties. Please try again later.');
    }
  }, [error]);

  return (
    <div className="my-8 px-4 lg:pt-8">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold text-center mb-4 lg:mb-8 text-gunmetal">{page === "properties" ? "Lista de Propiedades" : "Propiedades Destacadas"}</h2>
          <div className={`${page === "properties" ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 lg:mt-10' : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:mt-10'}`}>
            {displayedProperties.map((property) => (
              <div
                key={property.id}
                className={`relative border rounded-lg overflow-hidden shadow-md flex flex-col ${property.highlightedText && page === "properties"
                    ? 'pt-12 border-1 border-gunmetal  px-4 ' :
                    property.highlightedText && page === "home" ? 'pt-12 border-1 border-gunmetal  px-4 lg:scale-105 lg:z-10 mb-6 mx-4'
                      : 'border border-gray-300'
                  } transition-all duration-300 ${property.highlightedText ? 'order-1 lg:order-1' : 'order-2 lg:order-1'
                  }`}
              >
                {/* Mostrar la etiqueta si la propiedad tiene "highlightedText" */}
                {property.highlightedText && (
                  <div className="absolute w-full top-0 left-1/2 transform -translate-x-1/2 bg-gunmetal text-white text-sm sm:text-base font-bold px-4 py-1 rounded-t-lg inline-block text-center">
                    {property.highlightedText}
                  </div>
                )}

                {/* Contenedor de la imagen */}
                <LazyLoadImage
                  src={property.image}
                  alt={property.name}
                  className="w-full object-cover rounded"
                  fixedHeight="h-48"
                  containerClassName={`relative`}
                />

                {/* Detalles de la propiedad */}
                <div className="p-4 flex-grow flex flex-col">
                  <div className="text-center mb-4">
                    <h3 className="text-lg sm:text-xl font-semibold">{property.name}</h3>
                    <p className="text-gray-500 text-sm">{property.address}</p>
                    <p className="text-gray-700 font-medium text-lg">{property.price}</p>
                  </div>
                  <PropertyFeatures features={property.features} layout="home" />
                  <div className="text-center mt-auto">
                    <Link
                      to="/propertyDetails"
                      state={{ property }} // Pasar el objeto property como state
                      className="px-4 py-2 rounded bg-white text-charcoal border border-gunmetal font-semibold transition-all duration-300 hover:bg-gunmetal hover:text-white"
                    >
                      Ver propiedad
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default FeaturedProperties;