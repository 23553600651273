import React, { useEffect } from 'react';
import useFetchAboutUsDocument from '../../hooks/useFetchAboutUsDocument';
import CardWithConfetti from '../core/CardWithConfetti';
import Footer from '../core/footer/Footer';
import Loading from '../core/LoadingComponent';
import { toast } from 'react-toastify';

const AboutUs: React.FC = () => {
  const { aboutUseDocument, loading, error } = useFetchAboutUsDocument();
  const firstDocument = aboutUseDocument?.[0];

  // Parsear datos del documento
  const vision = firstDocument?.vision || 'Visión no disponible';
  const mission = firstDocument?.mission || 'Misión no disponible';
  const services = firstDocument?.services || [];
  const teamMembers = firstDocument?.teamMembers || [];

  useEffect(() => {
    if (error) {
      toast.error('Error loading data. Please try again later. '+error);
    }
  }, [error]);

  return (
    <div className="min-h-screen bg-gunmetal text-gray-800">
      <Loading loading={loading}>
        {/* Encabezado */}
        <header className="text-center py-16 bg-white shadow-md">
          <h1 className="text-4xl font-bold text-gunmetal mb-4">Sobre Nosotros</h1>
          <p className="max-w-2xl mx-auto text-lg text-charcoal">
            InmoTrust es una empresa dedicada a la compra, remodelación, arriendo y venta de bienes raíces propios. Nos
            comprometemos a maximizar el valor de cada propiedad mientras brindamos tranquilidad a nuestros clientes.
          </p>
        </header>

        {/* Visión y Misión */}
        <section className="py-16 px-6 lg:px-20 bg-gunmetal">
          <div className="flex flex-col lg:flex-row items-center lg:space-x-12">
            <div className="card-vision-mission lg:w-1/2 mb-8 lg:mb-0 relative p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-300 hover:-rotate-2 hover:shadow-2xl">
              <h2 className="text-3xl font-semibold text-gunmetal">Visión</h2>
              <p className="mt-4 text-charcoal">{vision}</p>
            </div>
            <div className="card-vision-mission lg:w-1/2 relative p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-300 hover:rotate-2 hover:shadow-2xl">
              <h2 className="text-3xl font-semibold text-gunmetal">Misión</h2>
              <p className="mt-4 text-charcoal">{mission}</p>
            </div>
          </div>
        </section>

        {/* Tarjetas desordenadas */}
        <section className="relative py-16 px-6 bg-charcoal">
          <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
            {services.map((service: any, index: number) => (
              <div
                key={service.id}
                className={`transform transition-transform duration-300 ${
                  index % 2 === 0 ? '-rotate-3 hover:rotate-0' : 'rotate-3 hover:rotate-0'
                }`}
              >
                <CardWithConfetti title={service.title} description={service.content} />
              </div>
            ))}
          </div>
        </section>

        {/* Equipo */}
        <section className="py-16 px-6 bg-gunmetal">
          <h2 className="text-3xl font-semibold text-center text-white mb-8">Nuestro Equipo</h2>
          <div className="flex flex-wrap justify-center gap-8">
            {teamMembers.map((member: any) => (
              <div
                key={member.name}
                className="flex flex-col items-center bg-gray-50 p-4 rounded-lg shadow-md transform transition-transform hover:scale-105"
              >
                <div className="h-24 w-24 rounded-full bg-gunmetal flex items-center justify-center text-white font-bold text-xl mb-4">
                  {member.name[0]}
                </div>
                <h3 className="text-lg font-semibold">{member.name}</h3>
                <p className="text-gray-600">{member.role}</p>
              </div>
            ))}
          </div>
        </section>

        <Footer />
      </Loading>
    </div>
  );
};

export default AboutUs;