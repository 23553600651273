import React, { useState } from 'react';
import PropertyFeatures from './PropertyFeature';
import MapComponent from './MapComponent';
import LazyLoadImage from './LazyLoadImage';
import { LatLngExpression } from 'leaflet';
import { Property } from '../../domain/model/Property';
import OffersSlider from '../home/OffersSlider';

interface PropertyDetailsModalProps {
  property: Property;
}

const PropertyDetails: React.FC<PropertyDetailsModalProps> = ({ property }) => {
  const location: LatLngExpression | null =
    property.location?._lat !== undefined && property.location?._long !== undefined
      ? [property.location._lat, property.location._long]
      : null;

  const images = [property.image, ...property.images.filter((img) => img !== property.image)];
  const [selectedImage, setSelectedImage] = useState(images[0]);

  console.log('valor Location ' + JSON.stringify(property.location));

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="container mx-auto p-6">
        {/* Imagen principal y miniaturas */}
        <div className="flex flex-col items-center mb-6">
          {/* Imagen principal */}
          <div className="w-full rounded-lg overflow-hidden shadow-lg relative">
  <LazyLoadImage
    src={selectedImage}
    alt={property.name}
    className="w-full object-contain h-full"
    loaderClassName="bg-gray-100"
    containerClassName="relative"
  />
</div>

          {/* Miniaturas debajo de la imagen principal */}
          <div className="mt-4 w-full md:w-3/4">
            <OffersSlider
              images={images}
              slidesToShow={2} // Número de miniaturas visibles
              slideSize="w-20 h-20" // Tamaño de cada miniatura
              onSlideChange={(image) => setSelectedImage(image)} // Actualiza automáticamente
              showDots={false}
              gap="4"
            />
          </div>
        </div>

        {/* Información de la propiedad */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <PropertyFeatures
            features={property.features}
            layout="details"
            address={property.address}
            price={property.price}
            isAvailable={property.status === 'Disponible'}
          />
          {location && (
            <MapComponent
              center={location}
              zoom={30}
              styleClass="w-full h-72 md:h-96 rounded-lg shadow-md"
              showPopup={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;