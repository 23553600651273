import { AboutUsDocumentRepositoryImpl, EmailConfigRepositoryImpl, FeatureConfigItemRepositoryImpl, OfferRepositoryImpl, OurServicesRepositoryImpl, PropertyRepositoryImpl } from "../../data/repositories/RepositoryImpl";
import { GetAboutUsDocumentUseCase } from "./aboutUs/GetAboutUsDocumentUseCase";
import { GetEmailConfigUseCase } from "./emailConfig/EmailConfigUseCase";
import { GetFeatureConfigItemUseCase } from "./featureConfigItem/GetFeatureConfigItemUseCase";
import { GetOfferUseCase } from "./offer/GetOfferUeCase";
import { GetOurServicesrUseCase } from "./ourServices/GetOurServicesUseCase";
import { GetPropertiesUseCase } from "./properties/GetPropertiesUseCase";

const propertyRepository = new PropertyRepositoryImpl();
const offerRepository = new OfferRepositoryImpl()
const ourSevicesRepository = new OurServicesRepositoryImpl()
const aboutUsDocumentRepository = new AboutUsDocumentRepositoryImpl()
const featureConfigItemRepository = new FeatureConfigItemRepositoryImpl()
const emailConfigRepository = new EmailConfigRepositoryImpl()
export const getPropertiesUseCaseIntance = new GetPropertiesUseCase(propertyRepository);
export const getOfferUseCaseInstance = new GetOfferUseCase(offerRepository)
export const getOurServicesUseCaseInstance = new GetOurServicesrUseCase(ourSevicesRepository)
export const getFeatureConfigItemUseCase = new GetFeatureConfigItemUseCase(featureConfigItemRepository)
export const getAboutUsDocumentUseCaseInstance = new GetAboutUsDocumentUseCase(aboutUsDocumentRepository)
export const getEmailConfigUseCaseInstance = new GetEmailConfigUseCase(emailConfigRepository)