import React from 'react';
import FeatureIcon from './featureIcons';
import { featureConfig } from '../../core/featureConfig';
import { FaCheck } from 'react-icons/fa';

interface PropertyFeaturesProps {
  features: string[];
  layout: 'home' | 'details';
  address?: string;
  price?: string;
  isAvailable?: boolean;
}

const PropertyFeatures: React.FC<PropertyFeaturesProps> = ({ features, layout, address, price, isAvailable }) => {
  const gridClasses = layout === 'home' ? 'grid grid-cols-2 gap-4 mb-4' : 'grid grid-cols-2 gap-x-6 gap-y-2';
  const displayStatus = isAvailable ? 'Disponible' : 'No Disponible';

  return (
    <div>
      {layout === 'details' && (
        <>
          <p className="text-gunmetal text-lg mb-2">{address || 'Dirección no disponible'}</p>
          <p className="text-xl font-medium text-gunmetal mb-2">{price || 'Precio no disponible'}</p>
          <p className={`text-lg mb-4 flex items-center ${isAvailable ? 'text-green-600' : 'text-gray-400 line-through'}`}>
            {isAvailable ? <FaCheck className="mr-2" /> : null}
            {displayStatus}
          </p>
          <h3 className="text-lg font-semibold mb-2">Características:</h3>
        </>
      )}

      <div className={gridClasses}>
        {/* Características presentes en `features` */}
        {features.map((feature, idx) => (
          <div key={idx} className={`flex items-center text-sm ${layout === 'home' ? 'text-gray-700' : 'text-charcoal'}`}>
            <FeatureIcon feature={feature} />
            <span className="mr-2">{feature}</span>
          </div>
        ))}

        {/* Características de `featureConfig` no presentes en `features` */}
        {Object.keys(featureConfig).map((feature) => {
          const isPresent = features.some((f) =>
            featureConfig[feature].keywords.some((keyword) =>
              f.toLowerCase().includes(keyword.toLowerCase())
            )
          );

          return (
            !isPresent && (
              <div
                key={feature}
                className={`flex items-center text-sm ${layout === 'home' ? 'text-gray-400 line-through' : 'text-gray-400 line-through'}`}
              >
                <FeatureIcon feature={feature} />
                <span className="mr-1">{feature}</span>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default PropertyFeatures;