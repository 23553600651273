import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useFetchEmailConfig from '../../hooks/useFetchEmailConfig';

interface EmailFormProps {}

const EmailForm: React.FC<EmailFormProps> = () => {
  const form = useRef<HTMLFormElement>(null);
  const emailConfig = useFetchEmailConfig(); 
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const validate = () => {
    let tempErrors = { name: '', email: '', message: '' };
    let isValid = true;

    // Validar nombre
    if (!/^[A-Za-z ]+$/.test(formValues.name)) {
      tempErrors.name = 'El nombre solo puede contener letras y espacios.';
      isValid = false;
    }

    // Validar email
    if (!/^\S+@\S+\.\S+$/.test(formValues.email)) {
      tempErrors.email = 'El correo electrónico no es válido.';
      isValid = false;
    }

    // Validar mensaje
    if (!formValues.message.trim()) {
      tempErrors.message = 'El mensaje no puede estar vacío.';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailConfig) {
      toast.error('Configuración de correo no disponible.');
      return;
    }

    if (validate()) {
      if (form.current) {
        try {
          await emailjs.sendForm(
            emailConfig.serviceId,
            emailConfig.templateId,
            form.current,
            emailConfig.userId 
          );
          toast.success(emailConfig.successMessage)
          setFormValues({
            name: '',
            email: '',
            message: '',
          });
        } catch (error) {
          toast.error(emailConfig.errorMessage)
        }
      }
    }
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="space-y-4">
        <input
          type="text"
          name="name"
          placeholder="Nombre Completo"
          value={formValues.name}
          onChange={handleChange}
          className={`w-full p-3 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.name && <p className="text-red-500">{errors.name}</p>}

        <input
          type="email"
          name="email"
          placeholder="Correo Electrónico"
          value={formValues.email}
          onChange={handleChange}
          className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.email && <p className="text-red-500">{errors.email}</p>}

        <textarea
          name="message"
          rows={5}
          placeholder="Mensaje"
          value={formValues.message}
          onChange={handleChange}
          className={`w-full p-3 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.message && <p className="text-red-500">{errors.message}</p>}

        <div className="text-center mt-auto">
          <button
            type="submit"
            className="px-4 py-2 rounded bg-white text-charcoal border border-gunmetal font-semibold transition-all duration-300 hover:bg-gunmetal hover:text-white"
          >
            Enviar Mensaje
          </button>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default EmailForm;