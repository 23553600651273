import { useLocation } from "react-router-dom";
import Footer from "../../components/core/footer/Footer";
import PropertyDetails from "../../components/core/PropertyDetails";
import { Helmet } from "react-helmet-async";

const PropertyDetailsPage: React.FC = () => {
    const location = useLocation();
    const property = location.state?.property;
    return (
      <div className="flex flex-col min-h-screen">
        <Helmet>
        <title>InmoTrust | Administración de Propiedades</title>
        <meta name="description" content="InmoTrust, expertos en arriendo y administración de propiedades en Chile." />
        <meta name="keywords" content="InmoTrust, propiedades, arriendo, administración de propiedades, venta de casas en Chile" />
        <meta name="author" content="InmoTrust" />
      </Helmet>
        <div className="flex-grow">
        <PropertyDetails property={property} />
        </div>
        <Footer />
      </div>
    );
  };
  
  export default PropertyDetailsPage;