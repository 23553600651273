import { HelmetProvider } from "react-helmet-async";
import Navbar from "./components/Navbar/Navbar";
import AppRoutes from "./routes/AppRoutes";
import { BrowserRouter as Router } from 'react-router-dom';


function App() {
  return (
    <HelmetProvider>
       <Router>
      <Navbar />
      <AppRoutes />
    </Router>
    </HelmetProvider>
   
  );
}

export default App;
