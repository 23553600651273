import { useEffect, useState } from "react";
import { EmailConfig } from "../domain/model/EmailConfig";
import { getEmailConfigUseCaseInstance } from "../domain/usecase/useCaseInstances";

const useFetchEmailConfig = (): EmailConfig | undefined => {
  const [emailConfig, setEmailConfig] = useState<EmailConfig>();

  useEffect(() => {
    const fetchEmailConfig = async () => {
      try {
        const emailConfigData = await getEmailConfigUseCaseInstance.execute();
        setEmailConfig(emailConfigData);
      } catch (err) {
        console.error("Error fetching email configuration:", err);
      }
    };

    fetchEmailConfig();
  }, []);

  return emailConfig;
};

export default useFetchEmailConfig;