import { Helmet } from "react-helmet-async";
import Contact from "../../components/contact/Contact";
const ContactPage: React.FC = () => {

    return (
      <div className="flex flex-col min-h-screen">
        <Helmet>
        <title>InmoTrust | Administración de Propiedades</title>
        <meta name="description" content="InmoTrust, expertos en arriendo y administración de propiedades en Chile." />
        <meta name="keywords" content="InmoTrust, propiedades, arriendo, administración de propiedades, venta de casas en Chile" />
        <meta name="author" content="InmoTrust" />
      </Helmet>
        <div className="flex-grow">
          <Contact/>
        </div>
      </div>
    );
  };
  
  export default ContactPage;